
const $carousel = document.querySelector('.hero__carousel-content');
let $carousel_width = document.querySelector('.hero__carousel-content').offsetWidth;

const $no_imgs = document.querySelectorAll('.hero__main-image');

const $left_button = document.querySelector('#left');
const $right_button = document.querySelector('#right');
const $left_icon = document.querySelector('#left-icon');
const $right_icon = document.querySelector('#right-icon');

let $multiplier = 0;

// watch width of the carousel
function watchCarousel() {
    $carousel_width = document.querySelector('.hero__carousel-content').offsetWidth;
    $carousel.style.transform = `translateX(${-$multiplier * $carousel_width}px)`;
    for (let i = 1; i < $no_imgs.length; i++) {
        $carousel.children[i].style.transform = `translateX(${i * $carousel_width}px)`;
    }
}
watchCarousel();
new ResizeObserver(watchCarousel).observe($carousel);

// lazy loading off screen images
if ($no_imgs.length > 1) {
    for (let i = 1; i < $no_imgs.length; i++) {
        $no_imgs[i].setAttribute('loading', 'lazy');
        $carousel.children[i].style.transform = `translateX(${i * $carousel_width}px)`;
    }
}

const activateImages = () => {
    for (let i = 1; i < $carousel.children.length; i++) {
        let className = $carousel.children[1].className;
        let classArr = className.split(' ');
        let srcAttr = $carousel.children[1].getAttribute('src');
        let srcsetAttr = $carousel.children[1].getAttribute('srcset');
        let style = $carousel.children[1].getAttribute('style');
        $carousel.children[1].remove()
        const image = document.createElement('img');
        for (let i = 0; i < classArr.length; i++) {
            image.classList.add(classArr[i]);
        }
        image.setAttribute('src', srcAttr);
        image.setAttribute('srcset', srcsetAttr);
        image.setAttribute('style', style);
        $carousel.appendChild(image);
    }
    $right_button.removeEventListener('click', activateImages);
}

$right_button.addEventListener('click', activateImages)

$right_button.addEventListener('click', function () {
    if ($multiplier < $carousel.children.length - 1) {
        $multiplier++;
        $carousel.style.transform = `translateX(${-$multiplier * $carousel_width}px)`;
    }
    if ($multiplier == $carousel.children.length - 1) {
        $left_icon.classList.remove('hero__icon--disactive');
        $right_icon.classList.add('hero__icon--disactive');
    } else {
        $left_icon.classList.remove('hero__icon--disactive');
        $right_icon.classList.remove('hero__icon--disactive');
    }
})

$left_button.addEventListener('click', function () {
    if ($multiplier > 0) {
        $multiplier--;
        $carousel.style.transform = `translateX(${-$multiplier * $carousel_width}px)`;
    }
    if ($multiplier == 0) {
        $right_icon.classList.remove('hero__icon--disactive');
        $left_icon.classList.add('hero__icon--disactive');
    } else {
        $left_icon.classList.remove('hero__icon--disactive');
        $right_icon.classList.remove('hero__icon--disactive');
    }
})